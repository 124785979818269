import { Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import InvoiceLine from '../../models/InvoiceLine';
import Booker from '../../models/Booker';
import to from 'await-to-js';
import { invoiceService, dictionaryHelper } from '@/main';
import Booking from '../../models/Booking';
import CreateInvoiceBasePage from './CreateInvoiceBasePage';

@Component
export default class InvoiceCreateComponent extends CreateInvoiceBasePage {
    public isCommisionInvoice: boolean = false;

    @Watch('booking')
    public onBookingChange(val: Booking) {
        if (val) {
            this.mainBooker = val.mainBooker;
            this.invoice.mainBookerId = val.mainBookerId;
        }
    }

    @Watch('mainBooker')
    public onMainbookerChange(val: Booker) {
        if (val) {
            this.isLoadingBookings = true;
            this.bookings = this.allBookings.filter((booking) => {
                return booking.mainBookerId === val.mainBookerId;
            });
            this.isLoadingBookings = false;

            if (this.mainBooker.mainBookerId !== this.invoice.mainBookerId) {
                this.booking = null;
            }
        }
    }

    public async mounted() {
        await this.initSite();
        this.isCommisionInvoice = !!this.$route.query.homeOwnerId;

        const [bookers, products, bookings] = await Promise.all([this.getBookers(), this.getProducts(), this.getBookings()]);
        this.products = products.filter((x) => x.financeAccountId !== null);

        if (this.$route.params.invoiceId) {
            this.invoice = await this.getInvoice(this.$route.params.invoiceId);

            if (this.invoice.bookingId) {
                this.booking = this.invoice.booking = bookings.find((booking) => booking.bookingId === this.invoice.bookingId);
            }
            if (this.invoice.mainBookerId) {
                this.mainBooker = this.invoice.mainBooker = bookers.find((booker) => booker.mainBookerId === this.invoice.mainBookerId);
            }

            if (this.invoice.homeOwnerId) {
                this.mainBooker = this.invoice.mainBooker = bookers.find((booker) => booker.homeOwnerId === this.invoice.homeOwnerId);
                this.invoice.mainBookerId = this.mainBooker.mainBookerId;
            }

            if (!this.invoice.invoiceLines) {
                this.invoice.invoiceLines = [];
            }
        } else {
            if (this.isCommisionInvoice) {
                this.mainBooker = this.invoice.mainBooker = bookers.find(
                    (booker) => booker.homeOwnerId === parseInt(this.$route.query.homeOwnerId.toString(), 10),
                );
            }

            this.editMode = true;
        }

        this.allBookings = this.bookings = bookings;
        this.bookers = bookers;

        this.isLoading = false;
    }
}
